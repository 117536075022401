<template>
    <div>
        <!-- search input -->
        <section id="kb-category-search">
            <b-card no-body class="knowledge-base-bg text-center"
                :style="{ backgroundImage: `url(${require('@/assets/images/banner/banner.png')})` }">
                <b-card-body class="card-body">
                    <h2 class="text-primary">
                        Dedicated Source Used on Website
                    </h2>
                    <b-card-text class="mb-2">
                        <span>Popular searches: </span>
                        <span class="font-weight-bolder">Sales automation, Email marketing</span>
                    </b-card-text>

                    <!-- form -->
                    <b-form class="kb-search-input">
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                                <feather-icon icon="SearchIcon" />
                            </b-input-group-prepend>
                            <b-form-input id="searchbar" v-model="knowledgeBaseSearchQuery"
                                placeholder="Ask a question...." />
                        </b-input-group>
                    </b-form>
                    <!-- form -->
                </b-card-body>
            </b-card>
        </section>
        <!--/ search input -->

        <div id="knowledge-base-category">
            <b-row class="match-height">
                <b-col v-for="(data, index) in filteredKB" :key="index" md="4" sm="6">
                    <b-card>
                        <h6 class="kb-title">
                            <feather-icon :icon="data.icon" size="20" class="mr-50" :class="data.iconColor" />{{
                    data.title }} ({{ data.docs.length }})
                        </h6>
                        <b-list-group class="list-group-circle mt-2">
                            <b-list-group-item v-for="(que) in data.docs" :key="que.id" class="text-body"
                                :to="{ name: 'doc', params: {slug: que.slug } }">
                                {{ que.title }}
                            </b-list-group-item>
                        </b-list-group>
                    </b-card>
                </b-col>

                <!-- no result found -->
                <b-col v-show="!filteredKB.length" cols="12" class="text-center">
                    <h4 class="mt-4">
                        Search result not found!!
                    </h4>
                </b-col>
                <!--/ no result found -->
            </b-row>
        </div>
    </div>
</template>

<script>
import {
    BRow, BCol, BCard, BListGroup, BListGroupItem, BCardBody, BCardText, BForm, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BListGroup,
        BListGroupItem,
        BCardBody,
        BCardText,
        BForm,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
    },
    data() {
        return {
            knowledgeBaseSearchQuery: '',
            kb: [],
        }
    },
    computed: {
        filteredKB() {
            const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
            return this.kb.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.docs.filter(queObj => queObj.title.toLowerCase().includes(knowledgeBaseSearchQueryLower)).length)
        },
    },
    created() {
        this.getDocCategories()
    },
    methods: {
        getDocCategories(){
            axios.get('/doc_category/')
            .then(res => {
                console.log(res);
                this.kb = res.data
            })
            .catch(error => {
                console.log(error);
            })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>